<template>
  <div>
    <v-dialog v-model="dialog" width="80%" persistent>
      <v-card>
        <v-card-title class="grey lighten-2">
          รายชื่อ รับรายงานตัว รองผู้อำนวยการวิทยาลัย
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา"
            single-line
            hide-details
            dense
            filled
            class="mb-2"
          />

          <v-data-table
            :search="search"
            :items="sedirector_apps"
            :headers="headers"
          >
            <template v-slot:item.sedirector_app_college_report="{item}">
                <div>
                    <v-chip outlined color="warning" v-if="item.sedirector_app_college_report==='missing'"> <v-icon color="warning">mdi-close-circle</v-icon> ไม่มารายงานตัว  </v-chip>
                    <v-chip outlined color="success" v-else-if="item.sedirector_app_college_report==='receipt'"> <v-icon color="success">mdi-check-circle</v-icon> มารายงานตัว  </v-chip>
                    <v-chip outlined color="error" v-else> <v-icon color="error">mdi-alert-circle</v-icon> ไม่ได้ดำเนินการ  </v-chip>
                </div>
            </template>

            
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacher",
  props: ["OpenDialog_sedirector_app"],

  data() {
    return {
      ApiKey: "HRvec2021",
      dialog: false,
      period_sedirectors: [],
      sedirector_apps: [],
      headers: [
        { text: "จังหวัด", align: "center", value: "province_name" },      
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },      
        {
          text: "เลือกวิทยาลัย",
          align: "center",
          value: "collegenameChoose"
        },
        {
          text: "เลขที่ตำแหน่ง",
          align: "center",
          value: "sedirector_app_collegeapp_idpositions"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "sedirector_app_college_report"
        },

      ],
      search: ""
    };
  },

  async mounted() {},

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_process: "1",
        period_sedirector_id: "206"
      });
      this.period_sedirectors = result_period.data;
    },

    async sedirector_app() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,  
          sedirector_app_Collegereport: "ok"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async RunDialog() {
      if (this.OpenDialog_sedirector_app == true) {
        this.dialog = true;
      }
    },

    async closeDialog() {
      this.$emit("toggle-favorite", this.OpenDialog_sedirector_app);
      this.dialog = false;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  watch: {
    async OpenDialog_sedirector_app(newVal, oldVal) {
      if (this.OpenDialog_sedirector_app == true) {
        await this.sweetAlertLoading();
        await this.periodQuery();
        await this.sedirector_app();
        await this.RunDialog();
        Swal.close();
      }
    }
  }
};
</script>
