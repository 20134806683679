<template>
  <div>
   <v-container fluid>

  
        <base-material-card color="#4E342E" class="px-5 py-3">
          <template v-slot:heading>
            <div class="font-weight-light">
              <v-icon>mdi-cast</v-icon> ประกาศ วิทยาลัย
            </div>
            <h2 class="font-weight-light">ประกาศรับสมัครบุคลากร</h2>
          </template>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="searchNotiFy"
                  label="ค้นหา ประกาศ:"
                  class="mx-4"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  dense
                  filled
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="message"
                  label="ค้นหาใบสมัคร : เลขที่ใบสมัคร"
                  class="mx-4"
                  :append-icon="marker ? 'mdi-forward' : 'mdi-arrow-left-bold'"
                  :append-outer-icon="message ? 'mdi-printer' : 'mdi-keyboard'"
                  :prepend-icon="icon"
                  filled
                  clear-icon="mdi-close-circle"
                  clearable
                  type="text"
                  @click:append="toggleMarker"
                  @click:append-outer="sendMessage"
                  @click:prepend="changeIcon"
                  @click:clear="clearMessage"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="header_temporarycollegeemregs"
              :items="temporarycollegeemregs"
              :search="searchNotiFy"
            >
              <template v-slot:item.temCollegeEmRegPosition="{ item }">
                <span v-if="item.temCollegeEmRegPosition === 'governmentteach'"
                  >พนักงานราชการ(สายการสอน)</span
                >
                <span
                  v-else-if="item.temCollegeEmRegPosition === 'governmentsp'"
                  >พนักงานราชการ(สายสนับสนุน)</span
                >
                <span v-else-if="item.temCollegeEmRegPosition === 'temporary'"
                  >ครูอัตราจ้าง</span
                >
                <span
                  v-else-if="item.temCollegeEmRegPosition === 'temporary_job'"
                  >ลูกจ้างชั่วคราว</span
                >
              </template>

              <template v-slot:item.temCollegeEmRegHiring="{ item }">
                <span v-if="item.temCollegeEmRegHiring === 'month'"
                  >รายเดือน</span
                >
                <span v-else-if="item.temCollegeEmRegHiring === 'day'"
                  >รายวัน</span
                >
                <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
                  >รายชั่วโมง</span
                >
              </template>

              <template v-slot:item.temCollegeEmRegDateEnd="{ item }">
                <h4>
                  {{
                    item.temCollegeEmRegDateEnd
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </h4>               
              </template>

              <template v-slot:item.temCollegeEmRegDetail="{ item }">
                <v-btn
                  icon
                  :href="'/HRvecfiles/' + item.temCollegeEmRegDetail"
                  target="_blank"
                >
                  <v-icon color="info">mdi-file-document</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.temCollegeEmStatus="{ item }">
                <div class="font-weight-bold">
                  <div v-if="item.temCollegeEmStatus === '1'">
                    <v-icon color="green">mdi-lightbulb-on</v-icon>
                  </div>
                  <div v-else-if="item.temCollegeEmStatus === '0'">
                    <v-icon color="black">mdi-lightbulb</v-icon>
                  </div>
                </div>
              </template>

              <template v-slot:item.registorAction="{ item }">
                <v-btn
                  class="ma-1"
                  color="primary"
                  @click="
                    ApplyforworkSubmit(item.temCollegeEmRegID), (dialog = true)
                  "
                >
                  <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
                  สมัครงาน</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>

        <ApplyforWork
          :temCollegeEmRegID="registorActions"
          :dialog.sync="dialog"
        />
     </v-container>
  </div>
</template>

<script>
import ApplyforWork from "./ApplyforWork.vue";
export default {
  name: 'HRvecHrApppoint',
components:{ApplyforWork,},
  data() {
    return {
        ApiKey: "HRvec2021",
        searchNotiFy: "",
        dialog:false,
        message: "HRApp....!",
        header_temporarycollegeemregs: [
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "ตำแหน่ง", align: "center", value: "temCollegeEmRegPosition" },
        {
          text: "รูปแบบการจ้าง",
          align: "center",
          value: "temCollegeEmRegHiring"
        },
        {
          text: "เงินจ้าง/เดือน",
          align: "center",
          value: "temCollegeEmRegSalary"
        },
        { text: "ปิดประกาศ", align: "center", value: "temCollegeEmRegDateEnd" },
        { text: "ค่าธรรมเนียม", align: "center", value: "temCollegeEmPay" },
        { text: "รายละเอียด", align: "center", value: "temCollegeEmRegDetail" },
        { text: "สถานะ", align: "center", value: "temCollegeEmStatus" },
        { text: "สมัคร", align: "center", value: "registorAction" }
      ],
      temporarycollegeemregs: [],
      registorActions: "",
      idReg: [],
      marker: true,
      iconIndex: 0,      
      show: false,
      icons: ["mdi-file-document"],
      temporaryemployeesSearch: []
    };
  },

 async mounted() {
    await this.makeid();
    await this.temporarycollegeemregQuery();
  },

  methods: {
    toggleMarker() {
      this.marker = !this.marker;
    },
     
    async sendMessage() {
      let result = await this.$http.post("temporaryemployees.php", {
        ApiKey: this.ApiKey,
        temEmIDReGis: this.message
      });

      this.temporaryemployeesSearch = result.data;
      let temEmIDReGiss = this.temporaryemployeesSearch.temEmIDReGis || 0;

      if (temEmIDReGiss.length > 5) {
        Swal.fire({
          icon: "success",
          title: "ค้นพบใบสมัคร",
          showConfirmButton: false,
          timer: 1500
        });

        window.open("#/print_temporaryemployees/" + temEmIDReGiss, "_blank");
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่พบใบสมัคร",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    changeIcon() {
      this.iconIndex === this.icons.length - 1
        ? (this.iconIndex = 0)
        : this.iconIndex++;
    },

    async temporarycollegeemregQuery() {
      let result;
      result = await this.$http.post("temporarycollegeemreg.php", {
        ApiKey: this.ApiKey
      });
      this.temporarycollegeemregs = result.data;
    },

    async ApplyforworkSubmit(temCollegeEmRegID) {
      let result = await this.$http.post("temporarycollegeemreg.php", {
        ApiKey: this.ApiKey,
        temCollegeEmRegID: temCollegeEmRegID
      });

      this.registorActions = result.data;
      this.registorActions.idReg = this.idReg;
    },
    async makeid() {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      this.idReg = result;
    }
  },
  computed:{
    icon() {
      return this.icons[this.iconIndex];
    }
  }
};
</script>