<template>
  <div>
    <!-- แจ้งเตือนย้ายครู -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          periodassteachTeachers.periodAssTeachEnable === '1'
        "
      >
        <v-row>
          <v-col cols="7">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row>
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    รายงานตัวครูผู้ช่วย ก่อนเลือกสถานศึกษา
                  </h2>
                  <h2>สามารถดำเนินการ <u>รายงานตัว</u></h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      periodassteachTeachers.periodAssTeachStop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    เวลา 16.30 น.
                  </h3>
                </v-col>

                <v-col class="shrink">
                  <v-btn to="/pages/manage_assistant_teacher"  color="success" outlined>
                    <v-icon size="40" class="mr-2">mdi-page-next</v-icon> ดำเนินการ</v-btn
                  >
                </v-col>


              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="5">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>รายงานตัวครูผู้ช่วย</u> :
                {{
                  periodassteachTeachers.periodAssTeachStop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                เวลา 16.30 น.
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="periodassteachTeachers.periodAssTeachStop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>


  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HRvecAlertMissionPersonnelTeach",
  components: { FlipCountdown },
  data() {
    return {
      ApiKey: "HRvec2021",
      periodassteachTeachers: [],
      periodassteach_38:[],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      }
    };
  },

  async mounted() {
    await this.periodassteachQueryTeacher(); /* ย้ายครู */
  },

  methods: {
    async periodassteachQueryTeacher() {
      let result;
      result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,       
        periodAssTeachID: "103"
      });
      this.periodassteachTeachers = result.data;   
      (result.data)   
    },


    
  }
};
</script>

<style lang="scss" scoped></style>
