<template>
  <div>
    <base-material-card color="#263238" class="px-5 py-3">
      <template v-slot:heading>
        <div class="font-weight-light">วิทยาลัย</div>

        <h2 class="font-weight-light">ข้อมูลวิทยาลัยที่อยู่ในระบบ</h2>
      </template>

      <v-card-text>
        <v-data-table :headers="headers" :items="colleges" :search="search">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="ค้นหา :"
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
export default {
  name: "HRvecReportCollege",

  data() {
    return {
      ApiKey: "HRvec2021",
      headers: [
        { text: "ชื่อวิทยาลัย", align: "left", value: "college_name" },
        { text: "อำเภอ", align: "left", value: "prefecture_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ประเภทวิทยาลัย", align: "left", value: "collegetype_name" },
        { text: "ประเภทอัตรากำลัง", align: "left", value: "name_ctm" },
        { text: "สถาบันการอาชีวศึกษา", align: "left", value: "veiVocalName" },
        { text: "เบอร์โทร", align: "left", value: "collegeinfo_phone" }
      ],
      colleges: [],
      search:'',
    };
  },

  async mounted() {
    await this.collegeQuery();
  },

  methods: {
    async collegeQuery() {
      let result;
      result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey
      });
      this.colleges = result.data;
    }
  }
};
</script>
