<template>
  <div>
    <!-- รายงานตัวครูผู้ช่วย รอบ-->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="periodassteachsCh.periodAssTeachEnable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
          
            <h2 class="red--text">
                    <v-icon color="#C20000">mdi-domain</v-icon> กจ2. สอ. สอศ.
                  </h2>

             <h2 class="primary--text">รายงานตัวเลือกสถานศึกษา ครูผู้ช่วย</h2>

              <h3>
              ครั้งที่
                {{ periodassteachsCh.periodAssTeachTimes }}/{{ periodassteachsCh.periodAssTeachYear }}
                <br />
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <v-btn color="info" to="/pages/manage_college_manpower"
                >ติดตามการรายงานตัวเลือกวิทยาลัย</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HRvecAlertMissionPersonnelAssTeach",
  components: { FlipCountdown},
  data() {
    return {
      ApiKey: "HRvec2021",
      periodassteachsCh: []
    };
  },

  async mounted() {
    await this.periodassteachChoose();
  },

  methods: {
    async periodassteachChoose() {
      let result_period;
      result_period = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1",
        periodAssTeachID: "101",
        labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      });
      this.periodassteachsCh = result_period.data;

      if (this.periodassteachsCh.periodAssTeachEnable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.periodassteachsCh.periodAssTeachStop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.periodassteachsCh.ApiKey = this.ApiKey;
            this.periodassteachsCh.periodAssTeachEnable = "0";
            await this.$http.post(
              "periodassteach.update.php",
              this.periodassteachsCh
            );
            await this.periodassteachChoose();
          }       
        }, 3000);
      }

    }
  }
};
</script>

<style lang="scss" scoped></style>
