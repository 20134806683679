<template>
  <div>
    <!-- แจ้งเตือนย้ายครู -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          periodTeachers.period_enable === '1' &&
            periodTeachers.period_type === 'teacher'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    ข้าราชการครู
                  </h2>
                  <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      periodTeachers.period_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    เวลา 16.30 น.
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  periodTeachers.period_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                เวลา 16.30 น.
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="periodTeachers.period_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>


      <!-- แจ้งเตือนย้าย 38 -->
      <!-- <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_38.period_enable === '1'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)
                  </h2>
                  <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_38.period_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    เวลา 16.30 น.
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  period_38.period_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                เวลา 16.30 น.
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="period_38.period_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover> -->


  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HRvecAlertMissionPersonnelTeach",
  components: { FlipCountdown },
  data() {
    return {
      ApiKey: "HRvec2021",
      periodTeachers: [],
      period_38:[],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      }
    };
  },

  async mounted() {
    await this.periodQueryTeacher(); /* ย้ายครู */
    //await this.periodQuery_38(); /* ย้าย 38 */
  },

  methods: {
    async periodQueryTeacher() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periodTeachers = result.data;

      if (this.periodTeachers.period_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(this.periodTeachers.period_stop + " 16:30:00");
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.periodTeachers.ApiKey = this.ApiKey;
            this.periodTeachers.period_enable = "0";
            await this.$http.post("period.update.php", this.periodTeachers);
            await this.periodQueryTeacher();
          }
        }, 3000);
      }
    },

  /*   async periodQuery_38() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_college_id: "38101"
      });
      this.period_38 = result.data;
      
      if (this.period_38.period_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(this.period_38.period_stop + " 16:30:00");
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_38.ApiKey = this.ApiKey;
            this.period_38.period_enable = "0";
            await this.$http.post("period.update.php", this.period_38);
            await this.periodQueryTeacher();
          }
        }, 3000);
      }
    } */

    
  }
};
</script>

<style lang="scss" scoped></style>
