<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" lg="3" v-for="item in showAlldata" :key="item.id">
        <base-material-stats-card
          :value="Number(item.countPerson).toLocaleString()"
          color="#01579B"
          icon="mdi-account-group"
          :title="item.position_name"
          sub-icon="mdi-clock"
          sub-text="ALL Personnel"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <div id="chart">
            <apexchart height="300"  type="donut" :options="chartOptions" :series="series">
            </apexchart>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <div id="chart">
            <apexchart height="300" type="donut" :options="chartOptions2" :series="series2">
            </apexchart>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card>
          <div id="chart">
            <apexchart :options="chartOptions3" :series="series3">
            </apexchart>
          </div>
        </v-card>
      </v-col>


    </v-row>
  </div>
</template>

<script>
export default {
  name: "HRvecReportPerson",

  data() {
    return {
      ApiKey: "HRvec2021",
      showAlldata_rang: [],
      showAlldata: {},
      series: [],
      chartOptions: {
        chart: {
          type: "donut"
        },
        title: {
          text: "จำนวนบุคลากร สอศ."
        },
        responsive: [
          {
            options: {
              chart: {},
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
/*  */
      series2: [],
      chartOptions2: {
        chart: {
          type: "donut"
        },
        title: {
          text: "ข้าราชการครู แบ่งตามวิทยฐานะ"
        },
        responsive: [
          {
            options: {
              chart: {},
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      showAlldata_retire:[],
      series3: [
        {
          name: 'Start',
          data: [],
        },       
      ],
      chartOptions3: {
        chart: {
          type: 'bar',
        },
        colors: ['#1ABC9C'],
        title: {
          text: 'ข้อมูลผู้เกษียณอายุราชการ',
        },      
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {},
      },

    };
  },

  async mounted() {
    await this.getAlldata();
    await this.getAlldata_rang();
    await this.getAlldata_retire();
  },

  methods: {
    async getAlldata() {
      let result = await this.$http.post("show_dashboard_all.php", {
        showCountAll: "ok"
      });
      this.showAlldata = result.data;   
      let valuesst = [];
      let labelText = [];
      let data = this.showAlldata;
      data.forEach(value => {
        labelText.push(value.position_name + " : " + value.countPerson);
        valuesst.push(
          parseFloat((value.countPerson * 100) / value.countAll).toFixed(2)
        );
      });

      let valueshowaf = valuesst.map(i => Number(i));
      this.series = valueshowaf;
      this.chartOptions = {
        chart: {
          type: "donut"
        },
        dataLabels: {
          enabled: false
        },

        labels: labelText,
        dataLabels: {
          formatter(val, opts) {
            return [val.toFixed(1) + "%"];
          }
        }
      };
    },

    async getAlldata_rang() {
      let result = await this.$http.post("show_dashboard_all.php",{
        showCountTeacher:"Ok"
      });
      this.showAlldata_rang = result.data;
      let valuesst = [];
      let labelText = [];
      let data = this.showAlldata_rang;
      data.forEach(value => {
        labelText.push(value.rang_name + " : " + value.countTeach);
        valuesst.push(
          parseFloat((value.countTeach * 100) / value.countAll).toFixed(2)
        );
      });

      let valueshowaf = valuesst.map(i => Number(i));
      this.series2 = valueshowaf;
      this.chartOptions2 = {
        chart: {
          type: "donut"
        },
        dataLabels: {
          enabled: false
        },

        labels: labelText,
        dataLabels: {
          formatter(val, opts) {
            return [val.toFixed(1) + "%"];
          }
        }
      };
    },

    async getAlldata_retire() {
      let result = await this.$http.post("show_dashboard_all.php",{
        showRetire:"Ok"
      });
      this.showAlldata_retire = result.data;  
      let labelText = []
      let valuesst = []   
      let data = this.showAlldata_retire
      data.forEach((value) => {
        labelText.push(value.position_name)
        valuesst.push(value.countRetrire)
   
      })

      let valueshowaf = valuesst.map((i) => Number(i))  
      this.series3 = [
        {
          name: 'Start',
          data: valueshowaf,
        },   
        (this.chartOptions3= {
          chart: {
            type: 'bar',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
          },
        

          legend: {
            tooltipHoverFormatter: function (val, opts) {
              return (
                val +
                ' - ' +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                ''
              )
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6,
            },
          },
          xaxis: {
            categories: labelText,
            labels: {
              trim: true,
            },
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return val + ' (จำนวน)'
                  },
                },
              },
            ],
          },
          grid: {
            borderColor: '#f1f1f1',
          },
        }),
      ]
    }

  },
  computed: {}
};
</script>
