<template>
  <div>
    <v-dialog v-model="dialog" width="80%" persistent>
      <v-card>
        <v-card-title class="grey lighten-2">
          รายชื่อ รับรายงานตัวครูผู้ช่วย
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา"
            single-line
            hide-details
            dense
            filled
            class="mb-2"
          />

          <v-data-table
            :search="search"
            :items="manage_assistant_teachers"
            :headers="headers"
          >
            <template v-slot:item.mt_examgroup="{ item }">
              <div class="text-center">
                <v-chip
                  dark
                  color="purple"
                  v-if="item.mt_examgroup === 'general'"
                >
                  ทั่วไป
                </v-chip>
                <v-chip dark color="indigo" v-else>
                  จชต.
                </v-chip>

                <v-chip
                  dark
                  color="warning"
                  v-if="item.mt_type_personnel === 'transfer_position'"
                >
                  ครูโอนย้าย
                </v-chip>

                <v-chip
                  dark
                  color="warning"
                  v-if="item.mt_type_personnel === 'local_dev'"
                >
                  ครูพัฒนาท้องถิ่น
                </v-chip>
              </div>
            </template>

            <template v-slot:item.mt_admissions_status="{ item }">
              <v-chip
                dark
                color="red"
                v-if="item.mt_admissions_status === 'missing'"
              >
                <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
              </v-chip>
              <v-chip
                dark
                color="primary"
                v-if="item.mt_admissions_status === 'receipt'"
              >
                <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-else
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                <v-icon>mdi-information</v-icon>ไม่ได้ดำเนินการ
              </v-chip>
            </template>
            <template v-slot:item.mt_status="{ item }">
              <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
                <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
              </v-chip>
              <v-chip dark color="green" v-else>
                <v-icon>mdi-lightbulb-on-outline</v-icon>
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacher",
  props: ["OpenDialog_manage_assistant_teacher"],

  data() {
    return {
      ApiKey: "HRvec2021",
      dialog: false,
      periodassteachs: [],
      manage_assistant_teachers: [],
      headers: [
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },       
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" },
        { text: "สถานะ", align: "left", value: "mt_status" },

        { text: "เลขที่ตำแหน่ง", align: "left", value: "mt_id_position" },
        { text: "ประเภท", align: "left", value: "mt_examgroup" },
        { text: "รายงานตัว", align: "left", value: "mt_admissions_status" }
      ],
      search: ""
    };
  },

  async mounted() {},

  methods: {
    async periodassteach() {
      let result_period;
      result_period = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1",
        periodAssTeachID: "102"
      });
      this.periodassteachs = result_period.data;
    },

    async manage_assistant_teacher() {
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_report: "Ok",
          showHome:"ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async RunDialog() {
      if (this.OpenDialog_manage_assistant_teacher == true) {
        this.dialog = true;
        
      }
    },

    async closeDialog() {
      this.$emit("toggle-favorite", this.OpenDialog_manage_assistant_teacher);
      this.dialog = false;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  watch: {
    async OpenDialog_manage_assistant_teacher(newVal, oldVal) {
      if (this.OpenDialog_manage_assistant_teacher == true) {
        await this.sweetAlertLoading();
        await this.periodassteach();
        await this.manage_assistant_teacher();
        await this.RunDialog();
        Swal.close();
      }
    }
  }
};
</script>
