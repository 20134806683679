<template>
  <v-container fluid>

    <base-material-card
      icon="mdi-clipboard-text"
      title="ข้อมูลจำนวนนักเรียน วันที่ 10 มิถุนายน"
      class="px-5 py-3"
    >
      <v-card class="mb-4 pa-2">
        <v-row>
          <v-col cols="12" md="6" class="text-right">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-data-table
        color="success"
        :loading="loading"
        :headers="headersummary"
        :items="rate_work_college_summarys"
        :search="search"
      >
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert"
          >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
        >
      </v-data-table>
    </base-material-card>

    <!--  -->
    <base-material-card
      icon="mdi-clipboard-text"
      :title="'ข้อมูลจำนวนนักเรียน วันที่ 10 มิถุนายน ' + year_s"
      class="px-5 py-3"
    >
      <v-card class="mb-4 pa-2">
        <v-row>
          <v-col cols="12" md="6" class="text-right">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-data-table
        color="success"
        :loading="loading"
        :headers="headers"
        :items="rate_work_college_sums"
        :search="search"
      >
        <template v-slot:item.rate_work_college_id_class="{ item }">
          <span v-if="item.rate_work_college_id_class === '20'">ปวช.</span>
          <span v-else-if="item.rate_work_college_id_class === '30'">ปวส.</span>
          <span v-else-if="item.rate_work_college_id_class === '40'">ทล.บ</span>
        </template>

        <template v-slot:item.temCollegeEmRegHiring="{ item }">
          <span v-if="item.temCollegeEmRegHiring === 'month'">รายเดือน</span>
          <span v-else-if="item.temCollegeEmRegHiring === 'day'">รายวัน</span>
          <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
            >รายชั่วโมง</span
          >
        </template>

        <template v-slot:item.temEmDateTime="{ item }">
          {{
            item.temEmDateTime
              | moment("add", "543 years")
              | moment("D MMMM YYYY")
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            color="yellow"
            @click.stop="temporaryemployeesEdit(item.temEmIDReGis)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:item.action_s="{ item }">
          <v-icon
            color="red"
            @click.stop="temporaryemployeesDelete(item.temEmIDReGis)"
          >
            mdi-delete
          </v-icon>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert"
          >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
        >
      </v-data-table>
    </base-material-card>

  
  </v-container>
</template>

<script>
export default {
  name: "HRvecStdsummary",

  data() {
    return {
      ApiKey: "HRvec2021",
      headers: [
        { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },
        { text: "ระดับ", align: "left", value: "rate_work_college_id_class" },
        {
          text: "จำนวน ปวช.1/ปวส.1",
          align: "center",
          value: "sumrate_work_field_study_lavel_1"
        },
        {
          text: "จำนวน ปวช.2/ปวส.2",
          align: "center",
          value: "sumrate_work_field_study_lavel_2"
        },
        {
          text: "จำนวน ปวช.3",
          align: "center",
          value: "sumrate_work_field_study_lavel_3"
        }
      ],
      headersummary: [
        { text: "ปี", align: "left", value: "rate_work_college_year" },
        {
          text: "จำนวน ปวช.1/ปวส.1",
          align: "center",
          value: "sumrate_work_field_study_lavel_1"
        },
        {
          text: "จำนวน ปวช.2/ปวส.2",
          align: "center",
          value: "sumrate_work_field_study_lavel_2"
        },
        {
          text: "จำนวน ปวช.3",
          align: "center",
          value: "sumrate_work_field_study_lavel_3"
        },
        {
          text: "รวม",
          align: "center",
          value: "sumrate_work_field_study_lavel_all"
        }
      ],
      search: "",
      rate_work_college_sums: [],
      rate_work_college_summarys: [],
      loading: false
    };
  },

  async mounted() {
    await this.rate_work_college_sumQuery();
    await this.rate_work_college_summaryQuery();
  },

  methods: {
    async rate_work_college_sumQuery() {
      let result = await this.$http.post("rate_work_college_sum.php", {
        ApiKey: this.ApiKey,
        sumtypesubject: "ok",
        rate_work_college_year: this.year_s
      });
      this.rate_work_college_sums = result.data;
    },
    async rate_work_college_summaryQuery() {
      let result = await this.$http.post("rate_work_college_sum.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_college_summarys = result.data;
    }
  },
  computed: {
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    }
  }
};
</script>

<style lang="scss" scoped></style>
