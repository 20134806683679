<template>
  <v-container fluid>
    <base-material-card color="success" class="px-5 py-3">
      <template v-slot:heading>
        <div class="font-weight-light">สาขาวิชาเอก</div>

        <h2 class="font-weight-light">
          ข้อมูลสาขาวิชา : การย้าย/เงือนไขการย้าย สายการสอน
        </h2>
      </template>
      <v-card-text>
        <v-data-table
          :headers="header_branchs"
          :items="showbranchdata"
          :search="search_branch"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search_branch"
              label="ค้นหา :"
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </v-container>
</template>

<script>
export default {
  name: "HRvecReportColleges",

  data() {
    return {
      ApiKey: "HRvec2021",
      header_branchs: [
        { text: "รหัสสาขา", align: "center", value: "id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "รายละเอียด", align: "left", value: "detail_branch" }
      ],
      showbranchdata: [],
      search_branch: ""
    };
  },

  async mounted() {
    await this.getAllbranchdata();
  },

  methods: {
    async getAllbranchdata() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.showbranchdata = result.data;
    }
  }
};
</script>
