var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"#4E342E"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"font-weight-light"},[_c('v-icon',[_vm._v("mdi-cast")]),_vm._v(" ประกาศ วิทยาลัย ")],1),_c('h2',{staticClass:"font-weight-light"},[_vm._v("ประกาศรับสมัครบุคลากร")])]},proxy:true}])},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"ค้นหา ประกาศ:","append-icon":"mdi-magnify","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.searchNotiFy),callback:function ($$v) {_vm.searchNotiFy=$$v},expression:"searchNotiFy"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"ค้นหาใบสมัคร : เลขที่ใบสมัคร","append-icon":_vm.marker ? 'mdi-forward' : 'mdi-arrow-left-bold',"append-outer-icon":_vm.message ? 'mdi-printer' : 'mdi-keyboard',"prepend-icon":_vm.icon,"filled":"","clear-icon":"mdi-close-circle","clearable":"","type":"text"},on:{"click:append":_vm.toggleMarker,"click:append-outer":_vm.sendMessage,"click:prepend":_vm.changeIcon,"click:clear":_vm.clearMessage},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.header_temporarycollegeemregs,"items":_vm.temporarycollegeemregs,"search":_vm.searchNotiFy},scopedSlots:_vm._u([{key:"item.temCollegeEmRegPosition",fn:function(ref){
var item = ref.item;
return [(item.temCollegeEmRegPosition === 'governmentteach')?_c('span',[_vm._v("พนักงานราชการ(สายการสอน)")]):(item.temCollegeEmRegPosition === 'governmentsp')?_c('span',[_vm._v("พนักงานราชการ(สายสนับสนุน)")]):(item.temCollegeEmRegPosition === 'temporary')?_c('span',[_vm._v("ครูอัตราจ้าง")]):(item.temCollegeEmRegPosition === 'temporary_job')?_c('span',[_vm._v("ลูกจ้างชั่วคราว")]):_vm._e()]}},{key:"item.temCollegeEmRegHiring",fn:function(ref){
var item = ref.item;
return [(item.temCollegeEmRegHiring === 'month')?_c('span',[_vm._v("รายเดือน")]):(item.temCollegeEmRegHiring === 'day')?_c('span',[_vm._v("รายวัน")]):(item.temCollegeEmRegHiring === 'hour')?_c('span',[_vm._v("รายชั่วโมง")]):_vm._e()]}},{key:"item.temCollegeEmRegDateEnd",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.temCollegeEmRegDateEnd,"add", "543 years"),"D MMMM YYYY"))+" ")])]}},{key:"item.temCollegeEmRegDetail",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":'/HRvecfiles/' + item.temCollegeEmRegDetail,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-file-document")])],1)]}},{key:"item.temCollegeEmStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[(item.temCollegeEmStatus === '1')?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-lightbulb-on")])],1):(item.temCollegeEmStatus === '0')?_c('div',[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-lightbulb")])],1):_vm._e()])]}},{key:"item.registorAction",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.ApplyforworkSubmit(item.temCollegeEmRegID), (_vm.dialog = true)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-multiple-plus")]),_vm._v(" สมัครงาน")],1)]}}])})],1)],1),_c('ApplyforWork',{attrs:{"temCollegeEmRegID":_vm.registorActions,"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }