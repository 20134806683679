<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>         
          <v-spacer></v-spacer>
          <v-toolbar-items>          
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters class="mb-6" justify="center">
          <v-col cols="12" md="8" class="pa-2">
            <v-card>
              <v-img
                height="50"
                src="https://images.unsplash.com/photo-1557683316-973673baf926?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8OHx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80"
              ></v-img>

              <v-card-title>แบบฟอร์มสมัคร</v-card-title>
              <v-card-text class="text-center">
                <h3>{{ temCollegeEmRegID.college_name }}</h3>

                ตำแหน่ง :

                <span
                  class="font-weight-bold"
                  v-if="
                    temCollegeEmRegID.temCollegeEmRegPosition ===
                      'governmentteach'
                  "
                  >พนักงานราชการ(สายการสอน)</span
                >
                <span
                  class="font-weight-bold"
                  v-else-if="
                    temCollegeEmRegID.temCollegeEmRegPosition === 'governmentsp'
                  "
                  >พนักงานราชการ(สายสนับสนุน)</span
                >
                <span
                  class="font-weight-bold"
                  v-else-if="
                    temCollegeEmRegID.temCollegeEmRegPosition === 'temporary'
                  "
                  >ครูอัตราจ้าง</span
                >
                <span
                  class="font-weight-bold"
                  v-else-if="
                    temCollegeEmRegID.temCollegeEmRegPosition ===
                      'temporary_job'
                  "
                  >ลูกจ้างชั่วคราว</span
                >

                ประเภทการจ้าง :
                <span
                  class="font-weight-bold"
                  v-if="temCollegeEmRegID.temCollegeEmRegHiring === 'month'"
                  >รายเดือน</span
                >
                <span
                  class="font-weight-bold"
                  v-else-if="temCollegeEmRegID.temCollegeEmRegHiring === 'day'"
                  >รายวัน</span
                >
                <span
                  class="font-weight-bold"
                  v-else-if="temCollegeEmRegID.temCollegeEmRegHiring === 'hour'"
                  >รายชั่วโมง</span
                >
                อัตราค่าจ้าง
                <span class="font-weight-bold">
                  {{
                    Number(
                      temCollegeEmRegID.temCollegeEmRegSalary
                    ).toLocaleString()
                  }}
                </span>
                บาท
                <br />
                รายละเอียดตามประกาศ :
                <v-btn
                  icon
                  :href="
                    '/HRvecfiles/' + temCollegeEmRegID.temCollegeEmRegDetail
                  "
                  target="_blank"
                >
                  <v-icon color="info">mdi-file-document</v-icon>
                </v-btn>
                <div class="ma-2 text-center">
                  ใบสมัครเลขที่ :
                  <span class="font-weight-bold">{{ temEmIDReGis }}</span>
                  (ใช้สำหรับพิมพ์ใบสมัคร/ติดตามสถานะ)
                </div>
              </v-card-text>

              <v-card-text>
                <v-row align="center">
                  <v-form ref="addtemporarycollegeemregform" lazy-validation>
                    <v-container no-gutters>
                      <v-layout wrap>
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            @input="validateInput()"
                            outlined
                            v-model="addtemporarycollegeemreg.temEmIDcard"
                            label="เลขที่บัตรประจำตัวประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card'
                            ]"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md4>
                          <v-autocomplete
                            :items="prefixs"
                            item-text="prefix_name"
                            item-value="prefix_name"
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="คำนำหน้าชื่อ"
                            v-model="addtemporarycollegeemreg.temEmTitles"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="ชื่อ"
                            v-model="addtemporarycollegeemreg.temEmFname"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="นามสกุล"
                            v-model="addtemporarycollegeemreg.temEmLname"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="สัญชาติ"
                            v-model="addtemporarycollegeemreg.temEmNationality"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="เชื้อชาติ"
                            v-model="addtemporarycollegeemreg.temEmEthnicity"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="ศาสนา"
                            v-model="addtemporarycollegeemreg.temEmReligion"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
                 

                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">ภูมิลำเนาบ้านเกิด</div>
                        </v-flex>

                        <v-flex md6>
                          <v-dialog
                            ref="dialog2"
                            v-model="modal2"
                            :return-value.sync="
                              addtemporarycollegeemreg.temEmBrithDay
                            "
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="addtemporarycollegeemreg.temEmBrithDay"
                                label="วันเดือนปีเกิด"
                                outlined
                                :rules="[v => !!v || '']"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="addtemporarycollegeemreg.temEmBrithDay"
                              scrollable
                              locale="th"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal2 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialog2.save(
                                    addtemporarycollegeemreg.temEmBrithDay
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home"
                            outlined
                            label="ภูมิลำเนาบ้านเกิด"
                            v-model="addtemporarycollegeemreg.temEmAddBorn"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="จังหวัด"
                            v-model="addtemporarycollegeemreg.temEmProvince"
                            required
                            :items="provinces"
                            item-text="province_name"
                            item-value="province_ID"
                            :rules="[v => !!v || '']"
                            @change="prefectureQuery()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="อำเภอ"
                            :items="prefectures"
                            item-text="prefecture_name"
                            item-value="prefecture_ID"
                            v-model="addtemporarycollegeemreg.temEmDistrictAm"
                            required
                            :rules="[v => !!v || '']"
                            @change="districtQuery()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="ตำบล"
                            :items="districts"
                            item-text="district_name"
                            item-value="district_ID"
                            v-model="addtemporarycollegeemreg.temEmDistrict"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">ที่อยู่ปัจจุบัน</div>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ที่อยู่ปัจจุบัน"
                            v-model="addtemporarycollegeemreg.temEmHomeIdNow"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ถนน"
                            v-model="addtemporarycollegeemreg.temEmRordNow"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="จังหวัด"
                            :items="provinces"
                            item-text="province_name"
                            item-value="province_ID"
                            v-model="addtemporarycollegeemreg.temEmProvinceNow"
                            required
                            :rules="[v => !!v || '']"
                            @change="prefectureSearch()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="อำเภอ"
                            :items="prefectures2"
                            item-text="prefecture_name"
                            item-value="prefecture_ID"
                            v-model="
                              addtemporarycollegeemreg.temEmDistrictAmNow
                            "
                            required
                            :rules="[v => !!v || '']"
                            @change="districtSearch()"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ตำบล"
                            :items="districts2"
                            item-text="district_name"
                            item-value="district_ID"
                            v-model="addtemporarycollegeemreg.temEmDistrictNow"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-phone-classic"
                            outlined
                            label="เบอร์โทรติดต่อ"
                            v-model="addtemporarycollegeemreg.temEmTel"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>

                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">การศึกษา</div>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวช. สาขา"
                            v-model="addtemporarycollegeemreg.temEmEduCh"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวช. จาก"
                            v-model="addtemporarycollegeemreg.temEmEduChFrom"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวส. สาขา"
                            v-model="addtemporarycollegeemreg.temEmEduSh"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวส. จาก"
                            v-model="addtemporarycollegeemreg.temEmEduShFrom"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปริญญาตรี. สาขา"
                            v-model="addtemporarycollegeemreg.temEmEduBache"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปริญญาตรี. จาก"
                            v-model="addtemporarycollegeemreg.temEmEduBacheFrom"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ อื่นๆ สาขา"
                            v-model="addtemporarycollegeemreg.temEmEduEtc"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ อื่นๆ จาก"
                            v-model="addtemporarycollegeemreg.temEmEduEtcFrom"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                        </v-flex>

                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-account-network"
                            outlined
                            label="มีประสบการณ์ในการทำงานด้าน"
                            v-model="addtemporarycollegeemreg.temEmExperWork"
                            required
                          ></v-text-field>
                        </v-flex>

                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-transit-transfer"
                            outlined
                            label="มีความรู้ความสามารถพิเศษด้าน"
                            v-model="addtemporarycollegeemreg.temEmExperSp"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-email"
                            outlined
                            label="Email สำหรับการติดตามสถานะ/การลงทะเบียน Gmail เท่านั้น"
                            v-model="addtemporarycollegeemreg.temEmEmail"
                            required
                            :rules="emailRules"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                  <v-col cols="12" md="12">
                    <div class="text-right">
                      <v-btn
                        color="primary"
                        @click="addtemporarycollegeemregSubmit()"
                      >
                        สมัคร
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <hr class="mb-2 hrStyle" />
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "HRvecApplyforWork",
  props: {
    dialog: {
      default: false
    },
    temCollegeEmRegID: {
      default: ""
    }
  },
  data() {
    return {
      notifications: false,
      sound: true,
      ApiKey: "HRvec2021",
      widgets: false,
      addtemporarycollegeemreg: {},
      prefixs: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      modal2: false,
      districts: [],
      districts2: [],
      prefectures: [],
      prefectures2: [],
      provinces: [],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    };
  },

  async mounted() {
    await this.prefixQuery();
    await this.provinceQuery();
  },

  methods: {
    async addtemporarycollegeemregSubmit() {
      if (this.$refs.addtemporarycollegeemregform.validate()) {
       
        this.addtemporarycollegeemreg.ApiKey = this.ApiKey;
        this.addtemporarycollegeemreg.temEmIDReGis = this.temEmIDReGis;
        this.addtemporarycollegeemreg.temEmIDcardIDMain = this.temCollegeEmRegID.temCollegeEmRegID;
        this.addtemporarycollegeemreg.temEmCollegeCode = this.temCollegeEmRegID.temCollegeEmRegCode;
        this.addtemporarycollegeemreg.temEmRegis = this.temCollegeEmRegID.temCollegeEmRegPosition;
        this.addtemporarycollegeemreg.temEmPosition = this.temCollegeEmRegID.temCollegeEmRegPositionDetail;
        this.addtemporarycollegeemreg.temEmSalary = this.temCollegeEmRegID.temCollegeEmRegSalary;
        this.addtemporarycollegeemreg.temEmDateTime = new Date();
        this.addtemporarycollegeemreg.temEmStatus = "send";        
        Swal.fire({
        title: "ยืนยันการสมัคร",
        text: "ตรวจสอบข้อมูลของท่านให้เรียบร้อยก่อนกดปุ่มยืนยัน รหัสสำหรับพิมพ์ใบสมัครจะจัดส่งไปยัง E-mail ของท่านตามที่กรอกข้อมูลไว้",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) { 
          await this.sweetAlertLoading()
          let result = await this.$http.post(
          "temporaryemployees.insert.php",
          this.addtemporarycollegeemreg
        );      
        if (result.data.status == true) {          
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });        
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });         
        }
        Swal.close();   
        this.dialog =false     
        }
      });
       
      }
    },

    async prefixQuery() {
      let result = await this.$http.post("prefix.php", {
        ApiKey: this.ApiKey
      });
      this.prefixs = result.data;
    },

    async districtQuery() {
      let result = await this.$http.post("districts.php", {
        ApiKey: this.ApiKey,
        prefecture_ID: this.addtemporarycollegeemreg.temEmDistrictAm
      });
      this.districts = result.data;
    },

    async districtSearch() {
      let result = await this.$http.post("districts.php", {
        ApiKey: this.ApiKey,
        prefecture_ID: this.addtemporarycollegeemreg.temEmDistrictAmNow
      });
      this.districts2 = result.data;
    },

    async prefectureQuery() {
      let result = await this.$http.post("prefecture.php", {
        ApiKey: this.ApiKey,
        province_ID: this.addtemporarycollegeemreg.temEmProvince
      });
      this.prefectures = result.data;
    },

    async prefectureSearch() {
      let result = await this.$http.post("prefecture.php", {
        ApiKey: this.ApiKey,
        province_ID: this.addtemporarycollegeemreg.temEmProvinceNow
      });
      this.prefectures2 = result.data;
    },

    async provinceQuery() {
      let result = await this.$http.post("province.php", {
        ApiKey: this.ApiKey
      });
      this.provinces = result.data;
    },

    close() {
      this.$emit("update:dialog", false);
    },
    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let value = this.addtemporarycollegeemreg.temEmIDcar;
      let i, sum;
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

  },
  computed: {
    temEmIDReGis() {
      let Regis = "HRApp";
      let temEmIDReGis =
        Regis +
        this.year_s +
        this.temCollegeEmRegID.temCollegeEmRegID +
        this.temCollegeEmRegID.idReg +
        parseInt(this.temCollegeEmRegID.CountREG + 1);
      return temEmIDReGis;
    },
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    },
    time_stamp() {
      let time = Date.now();
      return time;
    }
  }
};
</script>

<style lang="scss" scoped>
.hrStyle {
  border: solid 1px rgb(114, 108, 108);
}
</style>
